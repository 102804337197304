<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col>
              <h4>Job Card Search</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>Ticket Number</label>
              <b-form-input v-model="search.referenceNumber"
                            @keyup.enter="searchCards"></b-form-input>
            </b-col>
            <b-col cols="4">
              <label>Date Created From</label>
              <b-form-datepicker v-model="search.dateCreatedFrom"></b-form-datepicker>
            </b-col>
            <b-col cols="4">
              <label>Date Created To</label>
              <b-form-datepicker v-model="search.dateCreatedTo"></b-form-datepicker>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>Company</label>
              <b-input-group>
                <b-form-input disabled
                              v-model="search.company"></b-form-input>
                <b-input-group-append>
                  <b-button size="sm"
                            text="Button"
                            variant="secondary"
                            @click="openSearchModal">Search</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col cols="4">
              <label>Location</label>
              <b-form-input v-model="search.location"></b-form-input>
            </b-col>
          </b-row>
          <hr class="mx-3" />
          <b-row>
            <b-col cols="12"
                   class="text-right">
              <b-button variant="red"
                        squared
                        class="mr-2"
                        @click="clearFilter()">Cancel</b-button>
              <b-button variant="primary"
                        squared
                        @click="searchCards">Search</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col>
              <div class="d-flex justify-content-end">
                <download-csv :data="jsonData"
                              :name=this.fileName>
                  <b-button variant="primary"
                            squared
                            class="text-right"
                            @click="downloadMessage">Export to .xls</b-button>
                </download-csv>
              </div>
              <b-table striped
                       hover
                       :items="tableData.dataSource"
                       :fields="tableData.tableColumns"
                       :busy="tableData.isLoading"
                       @row-clicked="openCard"
                       :per-page="tableData.resultsPerPage"
                       id="jobCardTable"
                       :current-page="tableData.currentPage"
                       sort-icon-left>
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
                </template>
                <template #cell(createdDate)="row">
                  <b-row align-v="center">
                    <span class="mr-auto">{{ row.item.createdDate | dateTimeFilter }}</span>
                  </b-row>
                </template>
                <template #cell(dueDate)="row">
                  <b-row align-v="center">
                    <span class="mr-auto">{{ row.item.dueDate | dateTimeFilter }}</span>
                  </b-row>
                </template>
                <template #cell(actions)="row">
                  <b-row align-v="center"
                         align-h="end">
                    <b-button @click="openCard(row.item)"
                              class="btn-icon"
                              size="sm">
                      <b-icon-chevron-right></b-icon-chevron-right>
                    </b-button>
                  </b-row>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row align-h="center">
            <b-pagination v-model="tableData.currentPage"
                          :total-rows="jobCardRows"
                          :per-page="tableData.resultsPerPage"
                          aria-controls="jobCardTable"></b-pagination>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="search-company-modal"
             hide-footer
             size="xl"
             no-header
             @close="closeSearchModal()">
      <b-row>
        <b-col cols="4">
          <label>Name</label>
          <b-form-input v-model="request.company.companyName"></b-form-input>
        </b-col>
      </b-row>
      <hr class="mx-3" />
      <b-row>
        <b-col cols="12"
               class="text-right">
          <b-button variant="red"
                    squared
                    class="mr-2"
                    @click="clearCompanyFilter">Clear</b-button>
          <b-button variant="primary"
                    squared
                    @click="doSearchCompanies">Search</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-table striped
                 hover
                 :items="tableCompanyData.dataSource"
                 :fields="tableCompanyData.tableColumns"
                 :busy="tableCompanyData.isLoading"
                 @row-clicked="addCompany"
                 :per-page="tableCompanyData.resultsPerPage"
                 id="companySearchTable"
                 :current-page="tableCompanyData.currentPage"
                 sort-icon-left>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
            </div>
          </template>
          <template #cell(actions)="row">
            <b-row align-v="center"
                   align-h="end">
              <b-button @click="addCompany(row.item)"
                        size="sm"
                        class="btn-icon">
                <b-icon-chevron-right></b-icon-chevron-right>
              </b-button>
            </b-row>
          </template>
          <template #cell(slaLapsedTime)="row">
            <b-row align-v="center"
                   align-h="end">
              <b-badge v-bind:class="[row.item >= 50 ? 'badge badge-secondary' : 'badge badge-secondary']">{{ row.item }}</b-badge>
            </b-row>
          </template>
        </b-table>
      </b-row>
      <b-row align-h="center">
        <b-pagination v-model="tableCompanyData.currentPage"
                      :total-rows="rows"
                      :per-page="tableCompanyData.resultsPerPage"
                      aria-controls="companySearchTable"></b-pagination>
      </b-row>
      <b-row class="mt-4">
        <b-col class="text-right">
          <b-button variant="red"
                    class="mr-2"
                    squared
                    @click="closeSearchModal()">Cancel</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapMutations } from 'vuex';
import JsonCSV from "vue-json-csv";

Vue.component('downloadCsv', JsonCSV)

export default {
  name: 'searchjobCards',
  data: () => ({
    search: {
      referenceNumber: null,
      dateCreatedFrom: null,
      dateCreatedTo: null,
      company: null,
      companyid: null,
      location: null,
    },
    companySearch: {
      name: null
    },
    tableCompanyData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Company',
          key: 'description',
          sortable: true,
          tdClass: ''
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },
    tableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Ticket Number',
          key: 'referenceNumber',
          sortable: true,
          tdClass: '',
          thStyle: { width: "9%" }
        },
        {
          label: 'Opened Date',
          key: 'createdDate',
          sortable: true,
          tdClass: '',
          thStyle: { width: "9%" }
        },
        {
          label: 'Company',
          key: 'company',
          sortable: true,
          tdClass: '',
          thStyle: { width: "10%" }
        },
        {
          label: 'Location',
          key: 'location',
          sortable: true,
          tdClass: '',
          thStyle: { width: "10%" }
        },
        {
          label: 'Client',
          key: 'client',
          sortable: true,
          tdClass: '',
          thStyle: { width: "10%" }
        },
        {
          label: 'Subject',
          key: 'subject',
          sortable: true,
          tdClass: '',
          thStyle: { width: "28%" }
        },
        {
          label: 'Priority',
          key: 'priority',
          sortable: true,
          tdClass: '',
          thStyle: { width: "6%" }
        },
        {
          label: 'Due Date',
          key: 'dueDate',
          sortable: true,
          tdClass: '',
          thStyle: { width: "10%" }
        },
        {
          label: 'Status',
          key: 'status',
          sortable: true,
          tdClass: '',
          thStyle: { width: "10%" }
        },
        {
          //TODO  Add styling for the sla column
          label: 'SLA Time',
          key: 'slaLapsedTime',
          sortable: true,
          thStyle: { width: "8%" },
          // tdClass: '[time.toFixed() <= 100 ? {badge badge-secondary} : {badge badge-success}]',
          formatter: (value, key, item) => {
            let due = Date.parse(item.dueDate) ? Date.parse(item.dueDate) : 0;
            let created = Date.parse(item.createdDate);
            let today = new Date().getTime();

            let x = Math.round(due - created);
            // let left = Math.round(due - today);
            let elapsed = Math.round(today - created)
            let time = (elapsed / x) * 100;

            if (time > 100) {
              return 100 + '%';
            } else if (time < 0) {
              return 0 + '%';
            } else {
              return time.toFixed() + '%'
            }
          }
        },
        // {
        //     label: '',
        //     key: 'actions',
        //     sortable: false,
        //     tdClass: ''
        // }
      ]
    },
    jsonData: [],
    fileName: `Job Card ${new Date().toLocaleDateString('en-ZA')}.csv`,
    request: {
      company: { description: null },
    },
  }),
  created() {
    this.searchCards()
    this.setBreadcrumb([
      {
        text: 'Job Cards'
      },
      {
        text: 'Search',
        active: true
      }
    ])
  },
  methods: {
    ...mapActions(['searchJobCards', 'searchCompanies']),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    ...mapActions('notificationService', ['addWarningMessage', 'addSuccessMessage']),
    openSearchModal() {
      this.doSearchCompanies()
      this.$root.$emit('bv::show::modal', 'search-company-modal', '#btnShow')
    },
    closeSearchModal() {
      this.$root.$emit('bv::hide::modal', 'search-company-modal', '#btnShow')
    },
    doSearchCompanies() {
      this.tableCompanyData.isLoading = true
      let request = []

      if (this.request.company.companyName != null) {
        request.push({ 'key': 'companyName', 'value': this.request.company.companyName })
      }
      this.$store.commit('setSearchCompaniesRequest', request)
      this.searchCompanies()
        .then((response) => {
          this.tableCompanyData.dataSource = response.data
          this.tableCompanyData.isLoading = false
        })
        .catch(() => {
          this.tableCompanyData.isLoading = false
        })
    },
    clearCompanyFilter() {
      this.request = {
        company: {
          companyName: null
        }
      }
      this.doSearchCompanies()
    },
    openCard(item) {
      this.$store.commit('setSelectedJobCard', item)
      this.$router.push({ path: '/jobCards/view' })
    },
    clearFilter() {
      this.search = {
        referenceNumber: null,
        dateCreatedFrom: null,
        dateCreatedTo: null,
        company: null,
        location: null,
      }
      this.searchCards()
    },
    searchCards() {
      this.tableData.isLoading = true
      let request = []

      if (this.search.referenceNumber !== null && this.search.referenceNumber !== "") {
        request.push({ "key": "referenceNumber", "value": this.search.referenceNumber })
      }
      if (this.search.dateCreatedFrom !== null) {
        request.push({ "key": "createdDateFrom", "value": this.search.dateCreatedFrom })
      }
      if (this.search.dateCreatedTo !== null) {
        request.push({ "key": "createdDateTo", "value": this.search.dateCreatedTo })
      }
      if (this.search.company !== null) {
        request.push({ "key": "fkCompanyId", "value": this.search.companyid })
        //console.log("search :", this.search.company)
      }
      if (this.search.location !== null) {
        request.push({ "key": "locationName", "value": this.search.location })
      }

      this.$store.commit('setJobcardSearchRequest', request)
      this.searchJobCards()
        .then((res) => {
          this.tableData.dataSource = res.data
          this.jsonData = this.tableData.dataSource.map(v => ({
            referenceNumber: v.referenceNumber,
            createdDate: v.createdDate === null ? '' : new Date(v.createdDate).toLocaleDateString('en-ZA'),
            company: v.company,
            location: v.location,
            client: v.client,
            subject: v.subject,
            priority: v.priority,
            dueDate: v.dueDate === null ? '' : new Date(v.dueDate).toLocaleDateString('en-ZA')
          }))
          this.tableData.isLoading = false
        })
        .catch((err) => {
          console.log('Error', err)

          this.tableData.isLoading = false
        })
    },
    downloadMessage() {
      if (this.jsonData.length === 0) {
        this.addWarningMessage('There is no data to be downloaded')
      } else {
        this.addSuccessMessage('Your content has been downloaded ')
      }
    },
    addCompany(rowItem) {
      console.log('rowItem',rowItem)
      this.search.company = rowItem.description
      this.search.companyid = rowItem.id
      
      this.closeSearchModal()
    },
    // checkColourForVariant(rowItem) {
    //   if (rowItem.slaLapsedTime < 50) {
    //     return 'green'
    //   } else if (rowItem.slaLapsedTime < 80) {
    //     return 'orange'
    //   } else {
    //     return 'red'
    //   }
    // },
  },
  computed: {
    rows() {
      return this.tableCompanyData.dataSource.length
    },
    jobCardRows() {
      return this.tableData.dataSource.length
    },
  },
}
</script>
